import React, { useEffect, useState } from "react";
import PurchaseAccordion from "../../../components/accordion/PurchaseAccordion";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import styled from "styled-components";

const Purchases = () => {
  const [purchases, setPurchases] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [purchaseCount, setPurchaseCount] = useState();
  const axiosPrivate = useAxiosPrivate();

  function transformApiResponse(apiResponse) {
    return apiResponse.response.map((order, index) => ({
      title: `Order #${String(index + 1).padStart(5, "0")}`,
      items: order.items.map((item) => ({
        product_name: item.product_name,
        quantity: item.quantity,
        price_per_item: item.price_per_item,
        date: new Date(item.created_at).toLocaleDateString(),
      })),
      total: order.total_amount,
      status: order.status,
      customer_name: order.customer.name,
    }));
  }

  useEffect(() => {
    setTableLoading(true);
    const controller = new AbortController();

    const getPurchases = async () => {
      try {
        const response = await axiosPrivate.get("/purchase-record/get/", {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const processedData = transformApiResponse(response.data);
          if (processedData.length === 0) {
            setPurchaseCount(0);
          }
          setPurchases(processedData);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTableLoading(false);
      }
    };

    getPurchases();
    return () => {
      controller.abort();
    };
  }, [axiosPrivate]);

  return (
    <div>
      {tableLoading ? (
        <SkeletonTableView />
      ) : purchases.length === 0 ? (
        purchaseCount === 0 ? (
          <Error>No purchase record found!</Error>
        ) : (
          <SkeletonTableView />
        )
      ) : (
        purchases.map((purchase, index) => (
          <PurchaseAccordion
            key={index}
            title={purchase.title}
            items={purchase.items}
            total={purchase.total}
            status={purchase.status}
            customer_name={purchase.customer_name}
          />
        ))
      )}
    </div>
  );
};

export default Purchases;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;