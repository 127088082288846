import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Users, Package, Store, Truck } from "lucide-react";
import { useMediaQuery } from "react-responsive";
import Card from "../../../components/card/Card"; // Assuming Card component is in the same directory
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Customers from "../customers/Customers";
import Products from "../products/Products";
import ShopOwners from "../shopOwners/ShopOwners";
import Purchases from "../purchases/Purchases";
import MobileCard from "../../../components/card/MobileCard";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const CardsRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${(props) => (props.isMobile ? "20px" : "40px")};
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;

`;

const MobileTableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow-x: scroll;
`;

const MobileDashboardContainer = styled.div`
 
`;

const Dashboard = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const axiosPrivate = useAxiosPrivate();

  const [counts, setCounts] = useState({
    totalCustomers: 0,
    totalProducts: 0,
    totalShopOwners: 0,
    totalPurchases: 0,
  });
  const [activeComponent, setActiveComponent] = useState("shopOwners");

  useEffect(() => {
    const controller = new AbortController();

    const getCount = async () => {
      try {
        const response = await axiosPrivate.get(`/summary/get/count/`, {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const {
            total_customers,
            total_products,
            total_shop_owners,
            total_purchases,
          } = response.data.response;
          setCounts({
            totalCustomers: total_customers,
            totalProducts: total_products,
            totalShopOwners: total_shop_owners,
            totalPurchases: total_purchases,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    getCount();
    return () => {
      controller.abort();
    };
  }, [axiosPrivate]);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "purchase":
        return <Purchases />;
      case "customers":
        return <Customers />;
      case "products":
        return <Products />;
      case "shopOwners":
      default:
        return <ShopOwners />;
    }
  };

  return isDesktopOrLaptop ? (
    <DashboardContainer>
      <CardsRow isMobile={isMobile}>
        <Card
          icon={Store}
          title="Total Shop Owners"
          number={counts.totalShopOwners}
          onClick={() => setActiveComponent("shopOwners")}
        />
        <Card
          icon={Users}
          title="Total Customers"
          number={counts.totalCustomers}
          onClick={() => setActiveComponent("customers")}
        />
        <Card
          icon={Package}
          title="Total Products"
          number={counts.totalProducts}
          onClick={() => setActiveComponent("products")}
        />
        <Card
          icon={Truck}
          title="Total Purchase"
          number={counts.totalPurchases}
          onClick={() => setActiveComponent("purchase")}
        />
      </CardsRow>
      <TableContainer>{renderActiveComponent()}</TableContainer>
    </DashboardContainer>
  ) : (
    <MobileDashboardContainer>
      <div className="row me-0">
        <div className="col-6">
          <MobileCard
            icon={Store}
            title="Total Shop Owners"
            number={counts.totalShopOwners}
            onClick={() => setActiveComponent("shopOwners")}
          />
        </div>
        <div className="col-6">
          <MobileCard
            icon={Users}
            title="Total Customers"
            number={counts.totalCustomers}
            onClick={() => setActiveComponent("customers")}
          />
        </div>
      </div>
      <div className="row me-0">
        <div className="col-6">
          <MobileCard
            icon={Package}
            title="Total Products"
            number={counts.totalProducts}
            onClick={() => setActiveComponent("products")}
          />
        </div>

        <div className="col-6">
          <MobileCard
            icon={Truck}
            title="Total Purchase"
            number={counts.totalPurchases}
            onClick={() => setActiveComponent("purchase")}
          />
        </div>
      </div>
      <MobileTableContainer>{renderActiveComponent()}</MobileTableContainer>
    </MobileDashboardContainer>
  );
};

export default Dashboard;
