import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  Menu,
  X,
  LogOut,
  LayoutDashboard,
  Store,
  Users,
  Tag,
  Smartphone,
  Cpu,
} from "lucide-react";
import {
  BookUser,
  NotebookPen,
  Package,
  PackageOpen,
  SquareLibrary,
  UserPlus,
} from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import PopupModal from "../popupModal/PopupModal.jsx";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Mobileview = () => {
  const handleSignOut = () => {
    setModalOpen(true);
  };

  const handleConfirmSignOut = () => {
    setModalOpen(false);
    logout();
    toast.warning("You have been logged out!", {
      position: "top-center",
      autoClose: 2000,
      closeOnClick: true,
      closeButton: true,
      hideProgressBar: false,
      theme: "colored",
      containerId: "1",
    });
  };

  const handleCancelSignOut = () => {
    setModalOpen(false);
  };

  const role = Cookies.get("role");
  let menuItems = [];
  if (role === "Customer") {
    menuItems = [
      {
        icon: <SquareLibrary />,
        text: "View Purchase Records",
        to: "/customer/viewPurchaseRecord",
      },
      {
        icon: <LogOut />,
        text: "Sign Out",
        onClick: handleSignOut,
      },
    ];
  } else if (role === "Admin") {
    menuItems = [
      {
        icon: <LayoutDashboard />,
        text: "Dashboard",
        to: "/superuser/dashboard",
      },

      {
        icon: <Store />,
        text: "Shop Owner",
        to: "/superuser/store",
      },
      {
        icon: <Users />,
        text: "Customers",
        to: "/superuser/customers",
      },
      {
        icon: <Package />,
        text: "Orders",
        to: "/superuser/orders",
      },
      // {
      //   icon: <Truck />,
      //   text: "Products",
      //   to: "/superuser/products",
      // },
      // {
      //   icon: <PackageOpen />,
      //   text: "Add Product",
      //   to: "/superuser/addProducts",
      // },
      {
        icon: <UserPlus />,
        text: "Add Shop Owner",
        to: "/superuser/addShopOwner",
      },
      {
        icon: <Tag />,
        text: "Brands",
        to: "/superuser/brands",
      },
      {
        icon: <Smartphone />,
        text: "Devices",
        to: "/superuser/device",
      },
      {
        icon: <Cpu />,
        text: "Device Parts",
        to: "/superuser/deviceParts",
      },
    ];
  } else {
    menuItems = [
      {
        icon: <Package />,
        text: "Product Catalogue",
        to: "/admin/viewProducts",
      },
      { icon: <PackageOpen />, text: "Add Product", to: "/admin/addProducts" },
      {
        icon: <BookUser />,
        text: "Customer Directory",
        to: "/admin/viewCustomer",
      },
      { icon: <UserPlus />, text: "Add Customers", to: "/admin/addCustomer" },
      {
        icon: <NotebookPen />,
        text: "Purchase Entry",
        to: "/admin/addPurchaseRecord",
      },
      {
        icon: <SquareLibrary />,
        text: "Purchase Records",
        to: "/admin/viewPurchaseRecord",
      },
      {
        icon: <LogOut />,
        text: "Sign Out",
        onClick: handleSignOut,
      },
    ];
  }

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const menuRef = useRef(null);
  const { logout } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      AOS.refresh(); // Refresh AOS when menu opens to trigger animation
    }
  }, [menuOpen]);

  return (
    <div>
      <NavbarContainer>
        <div className="me-3 pointer" onClick={toggleMenu}>
          {menuOpen ? <X size={36} /> : <Menu size={36} />}
        </div>
      </NavbarContainer>

      {menuOpen && (
        <>
          <Backdrop onClick={closeMenu} />
          <AnimatedMenuContainer
            ref={menuRef}
            data-aos={menuOpen ? "slide-down" : "slide-up"}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                to={item.to}
                onClick={item.onClick ? item.onClick : closeMenu}
              >
                {item.icon}
                <span style={{ marginLeft: "10px" }}>{item.text}</span>
              </MenuItem>
            ))}
          </AnimatedMenuContainer>
        </>
      )}

      <PopupModal
        isOpen={modalOpen}
        title="Confirm Sign Out"
        message="Are you sure you want to sign out?"
        onConfirm={handleConfirmSignOut}
        onCancel={handleCancelSignOut}
      />
    </div>
  );
};

export default Mobileview;

const NavbarContainer = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: end;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: var(--color-primary);
  z-index: 1000;
`;

const AnimatedMenuContainer = styled.div`
  position: fixed;
  top: 65px; /* Adjust based on NavbarContainer height */
  left: 0;
  width: 100vw; /* Take full width of the viewport */
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
`;

const MenuItem = styled(NavLink)`
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 4px;
  color: var(--color-primary);

  &:hover {
    text-decoration: underline;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure backdrop is below the menu */
`;
