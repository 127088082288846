import React, { useEffect, useState } from "react";
import TableView from "../../../components/tableview/TableView";
import PopupModal from "../../../components/popupModal/PopupModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";

const ViewProducts = () => {
  const headings = ["Name", "Price", "Edit", "Delete"];
  const [productData, setProductData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [productCount, setProductCount] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState({
    id: "",
    name: "",
    price: "",
  });
  const axiosPrivate = useAxiosPrivate();

  function processApiResponse(apiResponse) {
    return apiResponse.response.map((item) => {
      return {
        id: item.id, // Add the product id for delete functionality
        Name: item.name,
        Price: `$${item.price.toFixed(2)}`,
      };
    });
  }

  const fetchProducts = async () => {
    setTableLoading(true);
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get("/products/get/", {
        signal: controller.signal,
      });

      if (response.data.errors) {
        console.log("Error:", response.data.errors);
      } else {
        const processedData = processApiResponse(response.data);
        if (processedData.length === 0) {
          setProductCount(0);
        }
        setProductData(processedData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTableLoading(false); // Ensure loading state is reset even if there is an error
    }
    return () => {
      controller.abort();
      setTableLoading(false);
    };
  };

  useEffect(() => {
    fetchProducts();
  }, [axiosPrivate]);

  const handleEdit = (row) => {
    setEditedProduct({
      id: row.id,
      name: row.Name,
      price: parseFloat(row.Price.replace("$", "")),
    });
    setEditModalOpen(true);
  };

  const handleEditChange = (field, value) => {
    setEditedProduct({ ...editedProduct, [field]: value });
  };

  const handleSaveEdit = async () => {
    try {
      await axiosPrivate.put(`/products/update/${editedProduct.id}/`, {
        name: editedProduct.name,
        price: editedProduct.price,
      });
      toast.success("Product has been updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      fetchProducts();
      setEditModalOpen(false);
    } catch (err) {
      console.error("Error updating product:", err);
      toast.error("Failed to update the product.", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
    }
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const handleDelete = (row) => {
    setDeleteTarget(row);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteTarget) {
      try {
        await axiosPrivate.put(`/products/delete/${deleteTarget.id}/`);
        toast.success("Product has been deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
        console.log("Deleted row:", deleteTarget);
        // Reload the table
        fetchProducts();
      } catch (err) {
        console.error("Error deleting product:", err);
        toast.error("Failed to delete the product.", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } finally {
        // Close the delete confirmation modal
        setDeleteTarget(null);
        setDeleteModalOpen(false);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteTarget(null);
    setDeleteModalOpen(false);
  };

  return (
    <div>
      {tableLoading ? (
        <SkeletonTableView />
      ) : productData.length === 0 ? (
        productCount === 0 ? (
          <Error>No products found!</Error>
        ) : (
          <SkeletonTableView />
        )
      ) : (
        <TableView
          headings={headings}
          data={productData}
          actions
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
      <PopupModal
        isOpen={deleteModalOpen}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${
          deleteTarget ? deleteTarget.Name : "this item"
        }?`}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      <ToastContainer />

      {/* Edit Modal */}
      {editModalOpen && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Edit Product</h2>
              <CloseButton onClick={() => setEditModalOpen(false)}>
                ×
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <div className="row mb-4">
                <Input
                  type="text"
                  label="Product Name"
                  id="productName"
                  className="form-control"
                  value={editedProduct.name}
                  onChange={(e) => handleEditChange("name", e.target.value)}
                />
              </div>
              <div className="row mb-2">
                <Input
                  type="number"
                  label="Product Price"
                  id="productPrice"
                  className="form-control"
                  value={editedProduct.price}
                  onChange={(e) => handleEditChange("price", e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                onClick={() => setEditModalOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveEdit}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default ViewProducts;

const Error = styled.div`
  color: red;
  font-size: 24px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
`;

const ModalBody = styled.div`
  padding: 20px 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 10px;
  border-top: 1px solid #ddd;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;
