import React, { useState, useRef } from "react";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { ScaleLoader } from "react-spinners";

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-end")};
`;

const AddShopOwners = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const controllerRef = useRef(null);

  const handleCancel = () => {
    setCustomerData({
      name: "",
      phone: "",
      email: "",
      password: "",
    });
    if (controllerRef.current) {
      controllerRef.current.abort(); // Abort the ongoing API call
    }
    setLoading(false);
  };

  const handleAddCustomer = async () => {
    if (!customerData.name || !customerData.phone || !customerData.email) {
      toast.error("All fields are required", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      return;
    }

    setLoading(true);
    const controller = new AbortController();
    controllerRef.current = controller;
    try {
      const response = await axiosPrivate.post(
        `/register/`,
        {
          full_name: customerData.name,
          mobile_number: customerData.phone,
          email: customerData.email,
          password: "akhnasgawai",
          role: "Shop Owner",
        },
        {
          signal: controller.signal,
        }
      );
      if (response.data.errors) {
        toast.error(response.data.errors[0].message, {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } else {
        handleCancel();
        toast.success("Shop Owner has been added successfully", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const error = err.response.data.errors;
        toast.error(Object.values(error)[0][0], {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } else {
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      }
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
      controllerRef.current = null;
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="row">
        <Input
          className="mb-3"
          placeholder="Enter Name"
          label="Name"
          type="text"
          name="name"
          value={customerData.name}
          onChange={handleChange}
        />
        <Input
          className="mb-3"
          placeholder="Enter Phone"
          label="Phone Number"
          type="Number"
          name="phone"
          value={customerData.phone}
          onChange={handleChange}
        />
        <Input
          className="mb-3"
          placeholder="Enter Email"
          label="Email"
          type="text"
          name="email"
          value={customerData.email}
          onChange={handleChange}
        />
        {/* <Input
          className="mb-3"
          placeholder="Enter Password"
          label="Password"
          type="password"
          name="password"
          value={customerData.password}
          onChange={handleChange}
        /> */}
      </div>
      <ButtonContainer isMobile={isMobile}>
        <Button variant="secondary" onClick={handleCancel} fullWidth={isMobile}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleAddCustomer}
          fullWidth={isMobile}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              Adding Shop Owner
              <ScaleLoader color="white" height={10} />
            </div>
          ) : (
            <>
              <i className="bi bi-plus-lg me-2"></i>Add Shop Owner
            </>
          )}
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default AddShopOwners;
