export const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: `var(--color-input-background)`,
      borderRadius: "5px",
      border: "none",
      boxShadow: "none",
      fontSize: "13px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 999,
      backgroundColor: state.isSelected
        ? `var(--color-primary)`
        : state.isFocused
        ? `var(--color-primary-light)` // Lighter shade on hover
        : "#fff",
      color: state.isSelected || state.isFocused ? "#000" : "#000",
      "&:hover": {
        backgroundColor: `var(--color-primary-light)`,
        color: "black",
      },
    }),
  };
  