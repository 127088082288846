import React from "react";
import styled from "styled-components";
import Button from "../button/Button";

const PopupModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContainer data-aos="slide-down" data-aos-once="true">
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onCancel}>×</CloseButton>
        </ModalHeader>
        <ModalBody>
          <Message>{message}</Message>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={onCancel} secondary>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Yes
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalBackground>
  );
};

export default PopupModal;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ModalTitle = styled.h2`
  font-size: 20px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #888;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const Message = styled.p`
  font-size: 16px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
