import React, { useEffect, useState } from "react";
import TableView from "../../../components/tableview/TableView";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import styled from "styled-components";

const Products = () => {
  const headings = ["Name", "Price"];
  const [products, setProducts] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [productCount, setProductCount] = useState();
  const axiosPrivate = useAxiosPrivate();

  function processApiResponse(apiResponse) {
    return apiResponse.response.map((item) => {
      return {
        Name: item.name,
        Price: `$${item.price.toFixed(2)}`,
      };
    });
  }

  useEffect(() => {
    setTableLoading(true);
    const controller = new AbortController();
    const getProducts = async () => {
      try {
        const response = await axiosPrivate.get("/products/get/all/", {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const processedData = processApiResponse(response.data);
          if (processedData.length === 0) {
            setProductCount(0);
          }
          setProducts(processedData);
        }
      } catch (err) {
        console.error(err);
        setTableLoading(false); // Ensure loading state is reset even if there is an error
      }
    };
    getProducts();
    return () => {
      controller.abort();
      setTableLoading(false);
    };
  }, [axiosPrivate]);

  return (
    <div>
      {products.length === 0 ? (
        productCount === 0 ? (
          <Error>No products found</Error>
        ) : (
          <SkeletonTableView />
        )
      ) : (
        <TableView headings={headings} data={products} />
      )}
    </div>
  );
};

export default Products;
const Error = styled.div`
  color: red;
  font-size: 24px;
`;
