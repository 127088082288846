import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import SelectField from "../../../components/selectfield/SelectField";
import TableView from "../../../components/tableview/TableView";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import { ScaleLoader } from "react-spinners";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import debounce from "lodash.debounce";
import Select from "react-select";
import { customStyles } from "../../../styles/selectStyles";
import BulkUpload from "./BulkUpload";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const AddDevicePartButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`;

const BulkUploadButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const SearchInput = styled(Input)`
  margin-bottom: 20px;
`;

const OwnerDevice = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceParts, setDeviceParts] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [devicePartCount, setDevicePartCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 0-based index for current page
  const itemsPerPage = 10; // Number of items per page
  const [isBulkUpload, setIsBulkUpload] = useState(false);

  const [formData, setFormData] = useState({
    brand: "", // Changed from "brands" to "brand" for single selection
    deviceName: "",
  });

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  const headings = ["Device", "Brand"];

  const fetchBrands = async () => {
    try {
      const response = await axiosPrivate.get("/brands/get/all");
      const brandOptions = response.data.response.map((brand) => ({
        value: brand.id,
        label: brand.brand_name,
      }));
      setBrands(brandOptions);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const fetchDevices = async (brandId) => {
    try {
      const response = await axiosPrivate.get("/devices/get/all/", {
        params: { brand_id: brandId },
      });
      const deviceOptions = response.data.response.map((device) => ({
        value: device.id,
        label: device.device_name,
      }));
      setDevices(deviceOptions);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const searchDevices = async (term, page = 0) => {
    setTableLoading(true);

    try {
      const response = await axiosPrivate.get(`/devices/get/all/`, {
        params: {
          device_name: term,
          brand_name: formData.brand, // Send selected brand
          page: page + 1, // Convert 0-based to 1-based for API
          page_size: itemsPerPage,
        },
      });

      const processedDevices = response.data.response.map((device) => ({
        "Device Name": device.device_name,
        Brand: device.brand_name,
      }));

      setDeviceParts(processedDevices); // Corrected the state update here
      setDevicePartCount(response.data.count); // Corrected the count update here
    } catch (error) {
      console.error("Error searching devices:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term, page) => searchDevices(term, page), 500),
    []
  );

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    debouncedSearch(searchTerm, currentPage);
  }, [searchTerm, debouncedSearch, currentPage]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setFormData({ brand: "", deviceName: "" });
    setDevices([]);
    setError("");
  };

  const handleBrandChange = (option) => {
    setFormData({ ...formData, brand: option.value });
    fetchDevices(option.value); // Fetch devices when a brand is selected
    setError(""); // Clear the error when brand changes
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear the error when input changes
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async () => {
    const { brand, deviceName } = formData;

    if (!brand || !deviceName.trim()) {
      setError("All fields are required");
      return;
    }

    setLoading(true);
    try {
      await axiosPrivate.post("/device/add/", {
        brand_id: brand,
        device_name: deviceName,
      });

      toast.success(`Device added: ${deviceName}`, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });

      toggleModal();
      searchDevices(searchTerm, currentPage); // Fetch updated devices list after adding a new device
    } catch (err) {
      toast.error("An error occurred while adding the device", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (selectedItem) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage); // Update the current page state
    debouncedSearch(searchTerm, selectedPage);
  };

  // Handle the selection of brands from the dropdown
  const handleFilterByBrands = async (selected) => {
    setTableLoading(true); // Start loading

    try {
      // Construct the query string with the selected brand IDs
      const queryString = selected
        .map((brand) => `brand_id=${brand.value}`)
        .join("&");

      // Use the constructed query string in the request URL
      const response = await axiosPrivate.get(
        `/devices/get/all/?${queryString}`
      );

      // Process the response to create brand options
      const processedDevices = response.data.response.map((device) => ({
        "Device Name": device.device_name,
        Brand: device.brand_name,
      }));

      setDeviceParts(processedDevices);
      setDevicePartCount(response.data.count);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setTableLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      {isBulkUpload ? (
        <BulkUpload setIsBulkUpload={setIsBulkUpload} />
      ) : (
        <>
          <Header>
            <Title>Devices</Title>
            <div className="d-flex gap-3">
              <BulkUploadButton
                variant="secondary"
                onClick={() => setIsBulkUpload(true)}
              >
                Bulk Upload
              </BulkUploadButton>
              <AddDevicePartButton onClick={toggleModal}>
                Add Device
              </AddDevicePartButton>
            </div>
          </Header>

          <div className="row">
            <div className="col-6">
              <Select
                placeholder="Filter by brands"
                isMulti
                options={brands}
                onChange={handleFilterByBrands}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: " #ff6969", // Color of the selected option
                  },
                })}
                styles={customStyles}
              />
            </div>
            <div className="col-6">
              <SearchInput
                placeholder="Search by device name"
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>

          <div>
            {tableLoading ? (
              <SkeletonTableView />
            ) : deviceParts.length === 0 && devicePartCount === 0 ? (
              <Error>No device found</Error>
            ) : (
              <TableView
                headings={headings}
                data={deviceParts}
                pageCount={Math.ceil(devicePartCount / itemsPerPage)}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                totalCount={devicePartCount}
              />
            )}
          </div>

          <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Add Device</ModalHeader>
            <ModalBody>
              <FormField>
                <SelectField
                  label="Select Brand"
                  options={brands}
                  value={formData.brand}
                  onChange={handleBrandChange}
                />
              </FormField>
              <FormField>
                <Input
                  label="Device Name"
                  name="deviceName" // Corrected the input name
                  value={formData.deviceName}
                  onChange={handleInputChange}
                  maxLength={30}
                />
              </FormField>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleModal} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="primary"
                disabled={loading}
              >
                {loading ? <ScaleLoader color="white" height={15} /> : "Submit"}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}

      <ToastContainer />
    </Container>
  );
};

export default OwnerDevice;
