import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "lucide-react";
import Button from "../../../components/button/Button";
import { useMediaQuery } from "react-responsive";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import PopupModal from "../../../components/popupModal/PopupModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/input/Input";

const ViewCustomer = () => {
  const [customerData, setCustomerData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [customerCount, setCustomerCount] = useState();
  const [expandedItem, setExpandedItem] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({
    id: "",
    name: "",
  });
  const axiosPrivate = useAxiosPrivate();

  function processApiResponse(apiResponse) {
    return apiResponse.response.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      phone: item.mobile,
    }));
  }

  useEffect(() => {
    setTableLoading(true);
    const controller = new AbortController();
    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.get("/get-my-customers/", {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const processedData = processApiResponse(response.data);
          if (processedData.length === 0) {
            setCustomerCount(0);
          }
          setCustomerData(processedData);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTableLoading(false); // Ensure loading state is reset even if there is an error
      }
    };
    getCustomers();
    return () => {
      controller.abort();
      setTableLoading(false);
    };
  }, [axiosPrivate]);

  const toggleExpand = (id) => {
    setExpandedItem(expandedItem === id ? null : id);
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleEdit = (item) => {
    setEditedCustomer({
      id: item.id,
      name: item.name,
    });
    setEditModalOpen(true);
  };

  const handleEditChange = (field, value) => {
    setEditedCustomer({ ...editedCustomer, [field]: value });
  };

  const handleSaveEdit = async () => {
    try {
      await axiosPrivate.put(`/user/edit/${editedCustomer.id}/`, {
        full_name: editedCustomer.name,
      });
      toast.success("Customer has been updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      fetchCustomers();
      setEditModalOpen(false);
    } catch (err) {
      console.error("Error updating customer:", err);
      toast.error("Failed to update the customer.", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
    }
  };

  const handleDelete = (item) => {
    setDeleteTarget(item);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteTarget) {
      try {
        await axiosPrivate.put(`/customer/delete/${deleteTarget.id}/`);
        toast.success("Customer has been deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
        // Reload the table
        fetchCustomers();
      } catch (err) {
        console.error("Error deleting customer:", err);
        toast.error("Failed to delete the customer.", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } finally {
        setDeleteTarget(null);
        setDeleteModalOpen(false);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteTarget(null);
    setDeleteModalOpen(false);
  };

  const fetchCustomers = async () => {
    setTableLoading(true);
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get("/get-my-customers/", {
        signal: controller.signal,
      });

      if (response.data.errors) {
        console.log("Error:", response.data.errors);
      } else {
        const processedData = processApiResponse(response.data);
        if (processedData.length === 0) {
          setCustomerCount(0);
        }
        setCustomerData(processedData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTableLoading(false); // Ensure loading state is reset even if there is an error
    }
    return () => {
      controller.abort();
      setTableLoading(false);
    };
  };

  return (
    <Container>
      {tableLoading ? (
        <SkeletonTableView />
      ) : customerData.length === 0 ? (
        customerCount === 0 ? (
          <Error>No customers found</Error>
        ) : (
          <SkeletonTableView />
        )
      ) : (
        customerData.map((item) => (
          <div key={item.id}>
            <Item>
              <Header onClick={() => toggleExpand(item.id)}>
                {item.name}
                {expandedItem === item.id ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </Header>
              {expandedItem === item.id && (
                <Content>
                  <div className="mb-3">
                    <div className="fw-bold">PHONE NUMBER</div>
                    <div>{item.phone}</div>
                  </div>
                  <div className="mb-3">
                    <div className="fw-bold">EMAIL</div>
                    <div>{item.email}</div>
                  </div>
                  <ButtonContainer isMobile={isMobile}>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(item)}
                      fullWidth={isMobile}
                    >
                      <i className="bi bi-pencil-fill me-2"></i>Edit
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleDelete(item)}
                      fullWidth={isMobile}
                    >
                      <i className="bi bi-trash me-2"></i>Delete
                    </Button>
                  </ButtonContainer>
                </Content>
              )}
            </Item>
            <Separator />
          </div>
        ))
      )}
      <PopupModal
        isOpen={deleteModalOpen}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${deleteTarget ? deleteTarget.name : "this customer"}?`}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      <ToastContainer />

      {/* Edit Modal */}
      {editModalOpen && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h2>Edit Customer</h2>
              <CloseButton onClick={() => setEditModalOpen(false)}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              <div className="row mb-4">
                <Input
                  type="text"
                  label="Customer Name"
                  id="customerName"
                  className="form-control"
                  value={editedCustomer.name}
                  onChange={(e) => handleEditChange("name", e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="secondary" onClick={() => setEditModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveEdit}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default ViewCustomer;

const Container = styled.div`
  width: 100%;
`;

const Item = styled.div`
  margin-bottom: 10px;
`;

const Header = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 10px;
  background-color: var(--color-light-accordion);
  border-radius: 4px;
  margin-top: 5px;
  padding-left: 20px;
`;

const Separator = styled.div`
  border-bottom: 1px solid var(--color-neutral-300);
  margin: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-start")};
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

