import React from "react";
import Select from "react-select";
import styled from "styled-components";
import Input from "../input/Input";

const Label = styled.label`
  display: block;
  margin-bottom: 0.1rem;
  color: var(--text-color);
  font-weight: 750;
  color: black;
  font-size: 15px;
  /* Add any additional styling here */
`;

const Container = styled.div`
  width: ${(props) => props.width || "100%"}; /* Set default width to 100% */
  margin-bottom: 1rem; /* Adjust spacing if needed */
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: `var(--color-input-background)`,
    borderRadius: "5px",
    border: "none",
    boxShadow: "none",
    fontSize: "13px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    zIndex: 999,
    backgroundColor: state.isSelected
      ? `var(--color-primary)`
      : state.isFocused
      ? `var(--color-primary-light)` // Lighter shade on hover
      : "#fff",
    color: state.isSelected || state.isFocused ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: `var(--color-primary-light)`,
      color: "black",
    },
  }),
};

const SelectField = ({
  options,
  value,
  label,
  onChange,
  name,
  disabled,
  width,
  isMulti, // Add isMulti to props
}) => {
  const selectedOption = isMulti
    ? options.filter((option) => value.includes(option.value))
    : options.find((option) => option.value === value);

  return (
    <Container width={width}>
      <Label>{label}</Label>
      {disabled ? (
        <Input type="text" value={value} disabled />
      ) : (
        <Select
          options={options}
          value={selectedOption}
          name={name}
          onChange={onChange}
          isMulti={isMulti} // Pass isMulti to Select component
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#28a745", // Color of the selected option
            },
          })}
          menuPortalTarget={document.body}
        />
      )}
    </Container>
  );
};

export default SelectField