import React, { useState } from "react";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styled from "styled-components";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!email || !password) {
      toast.error("Both email and password are required", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        // "http://65.2.148.18/vendor/api/login/",
        "https://backend.supplydost.com/vendor/api/login/",
        { email, password }
      );
      const { access, refresh } = response.data;

      // Decode the access token to get user information
      const decodedToken = jwtDecode(access);
      const userRole = decodedToken.role;
      const userName = decodedToken.name;
      const userEmail = decodedToken.email;

      if (userRole === "Customer") {
        const userId = decodedToken.user_id;
        Cookies.set("user_id", userId);
      }
      // Set additional cookies
      Cookies.set("username", userName);
      Cookies.set("role", userRole);
      Cookies.set("email", userEmail);

      // Log in the user in the context
      login(userRole, access, refresh);

      // Clear the input fields after successful submission
      setEmail("");
      setPassword("");

      // Show success message
      toast.success("Logged in successfully", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });

      // Navigate based on user role
      if (userRole === "Customer") {
        navigate("/customer/viewPurchaseRecord");
      } else if (userRole === "Admin") {
        navigate("/superuser/dashboard");
      } else {
        navigate("/admin/brands");
      }
    } catch (error) {
      console.log(error)
      const errorMessage = error.response?.data?.errors || "An unexpected error occurred";
      toast.error(`Login failed: ${errorMessage}`, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setEmail("");
    setPassword("");
  };

  return (
    <Container>
      <Card>
        <div className="col mb-3">
          <Header>Hello!</Header>
          <LighterHeader>Welcome Back</LighterHeader>
          <Input
            id="email-input"
            type="email"
            label="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="col mb-3">
          <Input
            id="password-input"
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="col mb-3">
          <Button
            id="submit-button"
            onClick={handleSubmit}
            variant="primary"
            fullWidth={true}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                Signing In
                <ScaleLoader color="white" height={10} />
              </div>
            ) : (
              <>Sign In</>
            )}
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://images.pexels.com/photos/4483610/pexels-photo-4483610.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
  background-position: center;
  opacity: 0.9;
`;

const Card = styled.div`
  padding: 20px;
  border-radius: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  width: 90%;
  max-width: 400px;
`;

const Header = styled.div`
  font-family: "Encode Sans", sans-serif;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: #1a1a1a;
`;

const LighterHeader = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #1a1a1a;
  margin-bottom: 20px;
`;
