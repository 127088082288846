import {
  BookUser,
  KeySquare,
  LayoutDashboard,
  NotebookPen,
  Package,
  PackageOpen,
  SquareLibrary,
  UserPlus,
  Users,
  Store,
  Truck,
  Tag,
  Smartphone,
  Cpu,
} from "lucide-react";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Cookies from "js-cookie";

const Sidebar = ({ collapseState, setCollapseState }) => {
  const role = Cookies.get("role");
  let menuItems = [];
  if (role === "Customer") {
    menuItems = [
      {
        icon: <SquareLibrary />,
        text: "Purchase Records",
        to: "/customer/viewPurchaseRecord",
      },
    ];
  } else if (role === "Admin") {
    menuItems = [
      {
        icon: <LayoutDashboard />,
        text: "Dashboard",
        to: "/superuser/dashboard",
      },

      {
        icon: <Store />,
        text: "Shop Owner",
        to: "/superuser/store",
      },
      {
        icon: <Users />,
        text: "Customers",
        to: "/superuser/customers",
      },
      {
        icon: <Package />,
        text: "Orders",
        to: "/superuser/orders",
      },
      // {
      //   icon: <Truck />,
      //   text: "Products",
      //   to: "/superuser/products",
      // },
      // {
      //   icon: <PackageOpen />,
      //   text: "Add Product",
      //   to: "/superuser/addProducts",
      // },
      {
        icon: <UserPlus />,
        text: "Add Shop Owner",
        to: "/superuser/addShopOwner",
      },
      {
        icon: <Tag />,
        text: "Brands",
        to: "/superuser/brands",
      },
      {
        icon: <Smartphone />,
        text: "Devices",
        to: "/superuser/device",
      },
      {
        icon: <Cpu />,
        text: "Device Parts",
        to: "/superuser/deviceParts",
      },
    ];
  } else {
    menuItems = [
     
      {
        icon: <BookUser />,
        text: "Customer Directory",
        to: "/admin/viewCustomer",
      },
      { icon: <UserPlus />, text: "Add Customer", to: "/admin/addCustomer" },
      // {
      //   icon: <NotebookPen />,
      //   text: "Purchase Entry",
      //   to: "/admin/addPurchaseRecord",
      // },
      {
        icon: <SquareLibrary />,
        text: "Purchase Records",
        to: "/admin/viewPurchaseRecord",
      },
      {
        icon: <Tag />,
        text: "Brands",
        to: "/admin/brands",
      },
      {
        icon: <Smartphone />,
        text: "Devices",
        to: "/admin/device",
      },
      {
        icon: <Cpu />,
        text: "Device Parts",
        to: "/admin/deviceParts",
      },
    ];
  }

  return (
    <div>
      <SideBarContainer collapseState={collapseState}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} to={item.to} collapseState={collapseState}>
            {collapseState ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-${index}`}>{item.text}</Tooltip>}
              >
                {item.icon}
              </OverlayTrigger>
            ) : (
              <>
                {item.icon}
                <span>{item.text}</span>
              </>
            )}
          </MenuItem>
        ))}
      </SideBarContainer>
      {/* <BottomMenuContainer collapseState={collapseState}>
        <BottomMenuItem to="">
          <KeySquare />
          {!collapseState && <span>Change Password</span>}
        </BottomMenuItem>
      </BottomMenuContainer> */}
    </div>
  );
};

export default Sidebar;

const SideBarContainer = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  width: ${({ collapseState }) => (collapseState ? "4rem" : "14rem")};
  height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  transition: width 0.4s ease;
`;

const MenuItem = styled(NavLink)`
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  background-color: var(--color-neutral-200);
  color: black;
  margin: 10px;
  text-decoration: none;
  transition: color 0.4s ease;

  span {
    opacity: ${({ collapseState }) => (collapseState ? "0" : "1")};
    transition: opacity 0.4s ease;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  &:hover {
    background-color: var(--color-neutral-300);
    /* color: var(--color-primary); */
  }
  &.active {
    background-color: var(--color-neutral-200);
    color: var(--color-primary);
    font-weight: bold; /* Example: Bold font for active item */
  }
`;

const BottomMenuContainer = styled.div`
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 0;
  width: ${({ collapseState }) => (collapseState ? "4rem" : "14rem")};
  transition: width 0.4s ease;
`;

const BottomMenuItem = styled(NavLink)`
  display: flex;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: var(--color-light-accordion);
  color: var(--color-primary);
  text-decoration: none;
  justify-content: center;
  gap: 10px;
  margin: 0 10px;
  transition: width 0.4s ease;

  span {
    opacity: ${({ collapseState }) => (collapseState ? "0" : "1")};
    transition: opacity 0.4s ease;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  &:hover {
    background-color: var(--color-neutral-300);
  }

  &.active {
    background-color: var(--color-neutral-300);
    font-weight: bold;
  }
`;
