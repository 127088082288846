import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const SkeletonTableView = () => {
  return (
    <div>
      <SkeletonTheme>
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
        <Skeleton />
        <Skeleton />
        <hr />
      </SkeletonTheme>
    </div>
  );
};

export default SkeletonTableView;
