import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MoveLeft, Plus } from "lucide-react";
import Button from "../../../components/button/Button";
import SelectField from "../../../components/selectfield/SelectField";
import Input from "../../../components/input/Input";
import ToggleButton from "../../../components/togglebutton/ToggleButton";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const PurchaseEntryPage = ({ setPurchaseEntry, onEntryComplete }) => {
  const axiosPrivate = useAxiosPrivate();
  const [customers, setCustomers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productEntries, setProductEntries] = useState([
    { brandId: "", deviceId: "", devicePartId: "", quantity: "", price: "", devices: [], deviceParts: [] },
  ]);
  const [purchaseData, setPurchaseData] = useState({
    customerName: "",
    paymentStatus: false,
  });

  useEffect(() => {
    const controller = new AbortController();

    const getCustomers = async () => {
      try {
        const response = await axiosPrivate.get(`/get-my-customers/`, {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const customersData = response.data.response.map((customer) => ({
            value: customer.id,
            label: customer.name,
          }));
          setCustomers(customersData);
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    getCustomers();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    const getBrands = async () => {
      try {
        const response = await axiosPrivate.get(`/brands/get/all/`, {
          signal: controller.signal,
        });

        if (response.data.errors) {
          console.log("Error:", response.data.errors);
        } else {
          const brandsData = response.data.response.map((brand) => ({
            value: brand.id,
            label: brand.brand_name,
          }));
          setBrands(brandsData);
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    getBrands();
    return () => {
      controller.abort();
    };
  }, []);

  const fetchDevicesByBrand = async (brandId, index) => {
    try {
      const response = await axiosPrivate.get(
        `/devices/get-device-by-brand/${brandId}/`
      );
      const devicesData = response.data.response.map((device) => ({
        value: device.id,
        label: device.device_name,
      }));
      updateProductEntry(index, { devices: devicesData, deviceId: "", devicePartId: "", deviceParts: [] });
    } catch (err) {
      console.error(err.response);
    }
  };

  const fetchDevicePartsByDevice = async (deviceId, index) => {
    try {
      const response = await axiosPrivate.get(
        `/device-parts/get-device-parts-by-device/${deviceId}/`
      );
      const devicePartsData = response.data.response.map((part) => ({
        value: part.id,
        label: part.part_name,
      }));
      updateProductEntry(index, { deviceParts: devicePartsData, devicePartId: "" });
    } catch (err) {
      console.error(err.response);
    }
  };

  const handleCustomerChange = (selectedOption) => {
    setPurchaseData({
      ...purchaseData,
      customerName: selectedOption.value,
    });
  };

  const handleAddProductEntry = () => {
    setProductEntries([
      ...productEntries,
      { brandId: "", deviceId: "", devicePartId: "", quantity: "", price: "", devices: [], deviceParts: [] },
    ]);
  };

  const handleProductEntryChange = async (index, field, value) => {
    if (field === "brandId") {
      await fetchDevicesByBrand(value, index);
    } else if (field === "deviceId") {
      await fetchDevicePartsByDevice(value, index);
    }

    updateProductEntry(index, { [field]: value });
  };

  const updateProductEntry = (index, updatedFields) => {
    setProductEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, ...updatedFields } : entry
      )
    );
  };

  const handlePaymentStatusChange = (status) => {
    setPurchaseData({
      ...purchaseData,
      paymentStatus: status,
    });
  };

  const handlePurchaseEntry = async () => {
    // Validate purchase data
    if (!purchaseData.customerName) {
      toast.error("Customer name is required", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      return;
    }

    if (productEntries.length === 0) {
      toast.error("At least one product entry is required", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        closeButton: true,
        hideProgressBar: false,
        theme: "colored",
        containerId: "1",
      });
      return;
    }

    // Validate each product entry
    for (let entry of productEntries) {
      if (
        !entry.brandId ||
        !entry.deviceId ||
        !entry.devicePartId ||
        !entry.quantity ||
        !entry.price
      ) {
        toast.error("All product fields must be filled", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
        return;
      }
    }

    const purchaseEntry = {
      customer_id: purchaseData.customerName,
      items: productEntries.map((entry) => ({
        brand_id: entry.brandId,
        device_id: entry.deviceId,
        device_part_id: entry.devicePartId,
        quantity: entry.quantity,
        price: entry.price,
      })),
      status: purchaseData.paymentStatus ? "Paid" : "Pending",
    };

    try {
      const response = await axiosPrivate.post(
        "/purchase-record/add/",
        purchaseEntry
      );

      if (response.data.errors) {
        toast.error(response.data.errors[0].message, {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } else {
        // Reset form data
        setPurchaseData({ customerName: "", paymentStatus: false });
        setProductEntries([
          {
            brandId: "",
            deviceId: "",
            devicePartId: "",
            quantity: "",
            price: "",
            devices: [],
            deviceParts: [],
          },
        ]);

        toast.success("Purchase entry added successfully!", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
        onEntryComplete();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errMsg = error.response.data.errors[0].message;
        toast.error(errMsg, {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      } else {
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          closeOnClick: true,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
          containerId: "1",
        });
      }
    }
  };

  return (
    <PageContainer>
      <MoveLeft className="pointer" onClick={() => setPurchaseEntry(false)} />
      <h2>Add Purchase Record</h2>
      <div className="row">
        <div className="col-11">
          <SelectField
            options={customers}
            label="Select Customer"
            value={purchaseData.customerName}
            onChange={handleCustomerChange}
          />
        </div>
        <div className="col-1">
          <AddButton onClick={handleAddProductEntry}>
            <Plus size={24} />
          </AddButton>
        </div>
      </div>

      <ProductEntriesContainer>
        {productEntries.map((entry, index) => (
          <div className="row mb-3" key={index}>
            <div className="col-md-3">
              <SelectField
                options={brands}
                label="Select Brand"
                value={entry.brandId}
                onChange={(option) =>
                  handleProductEntryChange(index, "brandId", option.value)
                }
              />
            </div>
            <div className="col-md-3">
              <SelectField
                options={entry.devices} 
                label="Select Device"
                value={entry.deviceId}
                onChange={(option) =>
                  handleProductEntryChange(index, "deviceId", option.value)
                }
                disabled={!entry.brandId}
              />
            </div>
            <div className="col-md-3">
              <SelectField
                options={entry.deviceParts} 
                label="Select Device Part"
                value={entry.devicePartId}
                onChange={(option) =>
                  handleProductEntryChange(index, "devicePartId", option.value)
                }
                disabled={!entry.deviceId}
              />
            </div>
            <div className="col-md-1">
              <Input
                type="number"
                label="Quantity"
                placeholder="0"
                value={entry.quantity}
                onChange={(e) =>
                  handleProductEntryChange(index, "quantity", e.target.value)
                }
              />
            </div>
            <div className="col-md-2">
              <Input
                type="number"
                label="Price"
                placeholder="Price"
                value={entry.price}
                onChange={(e) =>
                  handleProductEntryChange(index, "price", e.target.value)
                }
              />
            </div>
          </div>
        ))}
      </ProductEntriesContainer>
      <ToggleButton
        label="Has the payment for this purchase been completed?"
        onChange={handlePaymentStatusChange}
        checked={purchaseData.paymentStatus}
      />
      <ActionsContainer>
        <Button
          variant="secondary"
          onClick={() => {
            setPurchaseData({ customerName: "", paymentStatus: false });
            setProductEntries([
              {
                brandId: "",
                deviceId: "",
                devicePartId: "",
                quantity: "",
                price: "",
                devices: [],
                deviceParts: [],
              },
            ]);
          }}
        >
          Clear
        </Button>
        <Button variant="primary" onClick={handlePurchaseEntry}>
          Submit
        </Button>
      </ActionsContainer>
    </PageContainer>
  );
};

export default PurchaseEntryPage;

const PageContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin: 0 auto;
`;

const ProductEntriesContainer = styled.div`
  margin-bottom: 20px;
`;

const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6969;
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 20px auto;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;
