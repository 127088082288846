import React from "react";
import { Edit2, Trash2 } from "lucide-react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

// Styled components for pagination
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 5px;
    border: 1px solid #ff6969;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #ffb3b3;
    }

    &.active {
      background-color: #ff6969;
      color: white;
    }

    a {
      padding: 8px 12px;
      text-decoration: none;
      color: #ff6969;
      font-weight: bold;
      display: block;
      text-align: center;
    }

    &.disabled a {
      color: #e1e1e1;
      cursor: not-allowed;
    }
  }
`;

const TableView = ({
  headings,
  data,
  actions,
  onEdit,
  onDelete,
  itemsPerPage,
  currentPage,
  onPageChange,
  totalCount, 
}) => {
  const handlePageClick = ({ selected }) => {
    onPageChange(selected);
  };

  const filterRowData = (row) => {
    const { id, ...filteredRow } = row;
    return filteredRow;
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  return (
    <>
      <table className="table table-responsive table-hover">
        <thead
          style={{
            height: "60px",
            verticalAlign: "middle",
            borderRadius: "6px",
          }}
        >
          <tr>
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{
                  backgroundColor: "#e1e1e1",
                  borderTopLeftRadius: index === 0 ? "6px" : undefined,
                  borderTopRightRadius:
                    index === headings.length - 1 ? "6px" : undefined,
                }}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{ height: "60px", verticalAlign: "middle" }}
            >
              {Object.keys(filterRowData(row)).map((key, colIndex) => (
                <td key={colIndex}>{row[key]}</td>
              ))}
              {actions && (
                <td className="pointer">
                  {onEdit && (
                    <Edit2
                      color="#389be6"
                      size={20}
                      onClick={() => onEdit(row)}
                    />
                  )}
                </td>
              )}
              {actions && (
                <td className="pointer">
                  {onDelete && (
                    <Trash2
                      size={20}
                      color="#de3b40"
                      onClick={() => onDelete(row)}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationContainer>
        <div style={{ color: "#6c757d" }}>
          Showing {currentPage * itemsPerPage + 1} to{" "}
          {Math.min((currentPage + 1) * itemsPerPage, totalCount)} of{" "}
          {totalCount} results {/* Use totalCount here */}
        </div>
        <Pagination
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={onPageChange}
          forcePage={currentPage}
        />
      </PaginationContainer>
    </>
  );
};

export default TableView;
