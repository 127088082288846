import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background-color: #FCF3F3; /* Semi-transparent background */
  border: 1px solid #FF6969;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  max-width: 250px;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  cursor: pointer; /* Add a cursor pointer to indicate it's clickable */
`;

const CardIcon = styled.div`
  margin-bottom: 10px;
`;

const CardTitle = styled.h3`
  font-size: 0.7rem;
  margin-bottom: 10px;
`;

const CardNumber = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--color-primary);
`;

const MobileCard = ({ icon: Icon, title, number, onClick }) => (
  <CardContainer onClick={onClick}>
    <CardIcon>
      <Icon size={20} color="var(--color-primary)" />
    </CardIcon>
    <CardTitle>{title}</CardTitle>
    <CardNumber>{number}</CardNumber>
  </CardContainer>
);

export default MobileCard;
