import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import SelectField from "../../../components/selectfield/SelectField";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { ScaleLoader } from "react-spinners";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const OptionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const AddProducts = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    brand: "",
    deviceName: "",
    devicePart: "",
  });
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axiosPrivate.get("/brands/get/");
        const brandOptions = response.data.response.map((brand) => ({
          value: brand.id,
          label: brand.brand_name,
        }));

        setBrands(brandOptions);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);
  useEffect(() => {
    if (formData.brand) {
      const fetchDevices = async () => {
        try {
          const response = await axiosPrivate.get(`/devices/get/all`);
          const deviceOptions = response.data.response.map((device) => ({
            value: device.id,
            label: device.device_name,
          }));
          setDevices(deviceOptions);
        } catch (error) {
          console.error("Error fetching devices:", error);
        }
      };

      fetchDevices();
    }
  }, [formData.brand]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedOption(null);
    setFormData({ brand: "", deviceName: "", devicePart: "" });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (name, option) => {
    setFormData({ ...formData, [name]: option.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let response;
      switch (selectedOption) {
        case "brand":
          response = await axiosPrivate.post("/brand/add/", {
            brand_name: formData.brand,
          });
          toast.success(`Brand added: ${formData.brand}`, {
            position: "top-center",
            autoClose: 2000,
            closeOnClick: true,
            theme: "colored",
          });
          break;
        case "deviceName":
          response = await axiosPrivate.post("/device/add/", {
            brand_id: formData.brand, // Use formData.brand as the brand ID
            device_name: formData.deviceName,
          });
          toast.success(
            `Device added: Device Name - ${formData.deviceName}`,
            {
              position: "top-center",
              autoClose: 2000,
              closeOnClick: true,
              theme: "colored",
            }
          );
          break;
        case "deviceParts":
          const deviceId = formData.deviceName; // Use formData.deviceName as the device ID
          response = await axiosPrivate.post("/device-part/add/", {
            device_id: deviceId,
            part_name: formData.devicePart,
          });
          toast.success(
            `Device Part added: Device Part - ${formData.devicePart}`,
            {
              position: "top-center",
              autoClose: 2000,
              closeOnClick: true,
              theme: "colored",
            }
          );
          break;
        default:
          break;
      }
      toggleModal();
    } catch (err) {
      toast.error("An error occurred while adding the product info", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };
  

  const renderForm = () => {
    switch (selectedOption) {
      case "brand":
        return (
          <FormField>
            <Input
              label="Brand Name"
              placeholder="Enter a brand name"
              name="brand"
              value={formData.brand}
              onChange={handleInputChange}
            />
          </FormField>
        );
      case "deviceName":
        return (
          <>
            <FormField>
              {/* use Select field here  */}
              <SelectField
                label="Select Brand"
                options={brands}
                value={formData.brand}
                onChange={(option) => handleSelectChange("brand", option)}
              />
            </FormField>
            <FormField>
              <Input
                label="Device Name"
                placeholder="Enter a device name"
                name="deviceName"
                value={formData.deviceName}
                onChange={handleInputChange}
              />
            </FormField>
          </>
        );
      case "deviceParts":
        return (
          <>
            <FormField>
              <SelectField
                label="Select Brand"
                options={brands}
                value={formData.brand}
                onChange={(option) => handleSelectChange("brand", option)}
              />
            </FormField>
            <FormField>
              <SelectField
                label="Select Device Name"
                options={devices}
                value={formData.deviceName}
                onChange={(option) => handleSelectChange("deviceName", option)}
              />
            </FormField>
            <FormField>
              <Input
                label="Device Part"
                placeholder="Enter a device part"
                name="devicePart"
                value={formData.devicePart}
                onChange={handleInputChange}
              />
            </FormField>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <StyledButton onClick={toggleModal}>Add Product Info</StyledButton>

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          {selectedOption
            ? `Add ${selectedOption.replace(/([A-Z])/g, " $1")}`
            : "What would you like to add?"}
        </ModalHeader>
        <ModalBody>
          {!selectedOption ? (
            <OptionButton>
              <Button
                variant="secondary"
                onClick={() => handleOptionSelect("brand")}
              >
                Add Brand
              </Button>
              <Button
                variant="secondary"
                onClick={() => handleOptionSelect("deviceName")}
              >
                Add Device
              </Button>
              <Button
                variant="secondary"
                onClick={() => handleOptionSelect("deviceParts")}
              >
                Add Parts
              </Button>
            </OptionButton>
          ) : (
            renderForm()
          )}
        </ModalBody>
        {selectedOption && (
          <ModalFooter>
            <Button onClick={() => setSelectedOption(null)}>Back</Button>
            <Button color="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? <ScaleLoader color="white" height={10} /> : "Submit"}
            </Button>
          </ModalFooter>
        )}
      </Modal>
      <ToastContainer />
    </Container>
  );
};

export default AddProducts;
