import React, { useEffect, useState } from "react";
import PurchaseAccordion from "../../../components/accordion/PurchaseAccordion";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import Cookies from "js-cookie";
import Button from "../../../components/button/Button";
import styled from "styled-components";
import PurchaseEntryPage from "./PurchaseEntryPage";
import ReactPaginate from "react-paginate";

// Styled components for pagination
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 5px;
    border: 1px solid #ff6969;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #ffb3b3;
    }

    &.active {
      background-color: #ff6969;
      color: white;
    }

    a {
      padding: 8px 12px;
      text-decoration: none;
      color: #ff6969;
      font-weight: bold;
      display: block;
      text-align: center;
    }

    &.disabled a {
      color: #e1e1e1;
      cursor: not-allowed;
    }
  }
`;

const ViewPurchaseRecord = () => {
  const [tableLoading, setTableLoading] = useState(true);
  const [purchaseRecords, setPurchaseRecords] = useState([]);
  const [purchaseCount, setPurchaseCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5); // Number of items per page
  const [purchaseEntry, setPurchaseEntry] = useState(false); // Toggle between viewing records and entering a new record
  const axiosPrivate = useAxiosPrivate();
  const user_id = Cookies.get("user_id");

  const fetchPurchaseRecords = async (page = 0) => {
    setTableLoading(true);
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(`/purchase-record/get/`, {
        signal: controller.signal,
        params: {
          page: page + 1, // Convert to 1-based index for backend
          page_size: itemsPerPage,
        },
      });

      if (response.data.errors) {
        console.log("Error:", response.data.errors);
      } else {
        const processedData = transformApiResponse(response.data.response);
        setPurchaseCount(response.data.count); // Set the total record count from the response
        setPurchaseRecords(processedData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTableLoading(false);
    }

    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    fetchPurchaseRecords(currentPage);
  }, [axiosPrivate, user_id, currentPage]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handlePurchaseEntryComplete = () => {
    setPurchaseEntry(false);
    fetchPurchaseRecords(); // Re-fetch purchase records after new entry
  };


  function transformApiResponse(apiResponse) {
    return apiResponse.map((order, index) => ({
      title: `Order #${String(index + 1).padStart(5, "0")}`,
      items: order.items.map((item) => ({
        brand_name: `${item.brand_name}`,
        device_name: item.device_name,
        device_part_name: item.part_name,
        quantity: item.quantity,
        price_per_item: item.price_per_item,
      })),
      total: order.total_amount,
      status: order.status,
      customer_name: order.customer.name,
    }));
  }

  return (
    <div>
      {purchaseEntry ? (
        <PurchaseEntryPage 
          setPurchaseEntry={setPurchaseEntry} 
          onEntryComplete={handlePurchaseEntryComplete} 
        />
      ) : (
        <>
          <ButtonWrapper>
            <Button variant="primary" onClick={() => setPurchaseEntry(true)}>
              Add Purchase Record
            </Button>
          </ButtonWrapper>

          {tableLoading ? (
            <SkeletonTableView />
          ) : purchaseRecords.length === 0 ? (
            <Error>No orders found!</Error>
          ) : (
            <>
              {purchaseRecords.map((record, index) => (
                <PurchaseAccordion
                  key={index}
                  title={record.title}
                  items={record.items}
                  total={record.total}
                  status={record.status}
                  customer_name={record.customer_name}
                />
              ))}
              <PaginationContainer>
                <div style={{ color: "#6c757d" }}>
                  Showing {currentPage * itemsPerPage + 1} to{" "}
                  {Math.min((currentPage + 1) * itemsPerPage, purchaseCount)} of{" "}
                  {purchaseCount} results
                </div>
                <Pagination
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(purchaseCount / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                />
              </PaginationContainer>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ViewPurchaseRecord;

const Error = styled.div`
  color: red;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
