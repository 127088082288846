import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import TableView from "../../../components/tableview/TableView";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import Input from "../../../components/input/Input";
import debounce from "lodash.debounce"; // Import lodash debounce function
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const SearchInput = styled(Input)`
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const ShopOwners = () => {
  const axiosPrivate = useAxiosPrivate();
  const headings = ["Name", "Email", "Mobile"];
  const [shopOwners, setShopOwners] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [ownerCount, setOwnerCount] = useState(0); // Track total shop owners
  const [currentPage, setCurrentPage] = useState(0); // Track current page
  const itemsPerPage = 10; // Number of items per page
  const [searchTerm, setSearchTerm] = useState("");

  const processApiResponse = (apiResponse) => {
    return apiResponse.response.map((item) => ({
      Name: item.name,
      Email: item.email,
      Mobile: item.mobile,
    }));
  };

  const fetchShopOwners = async (page = 0) => {
    setTableLoading(true);
    try {
      const response = await axiosPrivate.get("/get-shop-owners/", {
        params: {
          page: page + 1, // Convert 0-based to 1-based for API
          page_size: itemsPerPage,
        },
      });
      const processedData = processApiResponse(response.data);

      setShopOwners(processedData);
      setOwnerCount(response.data.count); // Total count of shop owners
    } catch (err) {
      console.error("Error fetching shop owners:", err);
    } finally {
      setTableLoading(false);
    }
  };

  const searchShopOwners = async (term, page = 0) => {
    if (term) {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.get("/get-shop-owners/", {
          params: {
            full_name: term,
            page: page + 1, // Convert 0-based to 1-based for API
          },
        });

        const processedData = processApiResponse(response.data);

        setShopOwners(processedData);
        setOwnerCount(response.data.count); // Total count of shop owners
      } catch (err) {
        console.error("Error searching shop owners:", err);
      } finally {
        setTableLoading(false);
      }
    } else {
      fetchShopOwners(page);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term, page) => searchShopOwners(term, page), 500),
    []
  );

  useEffect(() => {
    fetchShopOwners(currentPage);
  }, [currentPage]);

  useEffect(() => {
    debouncedSearch(searchTerm, currentPage);
  }, [searchTerm, debouncedSearch, currentPage]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (selectedItem) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage); // Update the current page state
    if (searchTerm) {
      searchShopOwners(searchTerm, selectedPage);
    } else {
      fetchShopOwners(selectedPage);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Shop Owners</Title>
      </Header>

      <SearchInput
        placeholder="Search by owner name"
        value={searchTerm}
        onChange={handleSearchInputChange}
      />

      {tableLoading ? (
        <SkeletonTableView />
      ) : shopOwners.length === 0 && ownerCount === 0 ? (
        <Error>No shop owners found</Error>
      ) : (
        <TableView
          headings={headings}
          data={shopOwners}
          pageCount={Math.ceil(ownerCount / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          totalCount={ownerCount}
        />
      )}
    </Container>
  );
};

export default ShopOwners;
