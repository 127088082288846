import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../sidebar/Sidebar.jsx";
import PopupModal from "../popupModal/PopupModal.jsx";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";

const Webview = ({ collapseState, setCollapseState }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { logout } = useAuth();

  const handleSignOut = () => {
    setModalOpen(true);
  };

  const handleConfirmSignOut = () => {
    // Perform sign-out logic here
    setModalOpen(false);
    logout();
    toast.warning("You have been logged out!", {
      position: "top-center",
      autoClose: 2000,
      closeOnClick: true,
      closeButton: true,
      hideProgressBar: false,
      theme: "colored",
      containerId: "1",
    });
  };

  const handleCancelSignOut = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <NavbarContainer>
        <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
      </NavbarContainer>
      <Sidebar
        collapseState={collapseState}
        setCollapseState={setCollapseState}
      />

      <PopupModal
        isOpen={modalOpen}
        title="Confirm Sign Out"
        message="Are you sure you want to sign out?"
        onConfirm={handleConfirmSignOut}
        onCancel={handleCancelSignOut}
      />
    </div>
  );
};

export default Webview;

const NavbarContainer = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: end;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: var(--color-primary);
  z-index: 1000;
`;

const SignOutButton = styled.button`
  padding: 10px 15px; 
  margin-right: 20px;
  border-radius: 4px;
  border: none;
  color: var(--color-primary);
  font-weight: 500;

  &:hover {
    background-color:  #f4f4f4;
    border-color: #e55b5b;
    color: var(--color-primary);
  }
`;
