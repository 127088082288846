import React, { useState } from "react";
import styled from "styled-components";
import { MoveLeft } from "lucide-react";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import TableView from "../../../components/tableview/TableView";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-primary);
  margin-right: 16px;

  svg {
    margin-right: 8px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 30px;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  color: #666; /* Use a lighter color instead of black */
  margin-right: 20px;
  letter-spacing: 1px;
`;

const DownloadButton = styled(Button)`
  justify-content: end;
  font-size: 16px;
  padding: 10px 20px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken(var(--color-primary), 10%);
  }
`;

const UploadContainer = styled.div`
  padding: 40px;
  border: 2px dashed var(--color-neutral-300);
  background-color: var(--color-neutral-200);
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-neutral-300);
  }
`;

const UploadText = styled.p`
  font-size: 16px;
  color: #666;
`;

const BulkUpload = ({ setIsBulkUpload }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const handleBack = () => {
    setIsBulkUpload(false);
  };

  const handleDownloadTemplate = () => {
    // Logic to download the template
  };

  const handleUploadFiles = () => {
    // Logic to handle file upload
  };

  const headings = ["File", "Reason", "Status"];
  const data = [
    { File: "file1.xlsx", Reason: "Valid", Status: "Success" },
    { File: "file2.xlsx", Reason: "Missing Columns", Status: "Failed" },
    { File: "file3.xlsx", Reason: "Duplicate Data", Status: "Failed" },
    { File: "file4.xlsx", Reason: "Valid", Status: "Success" },
    { File: "file5.xlsx", Reason: "Invalid Format", Status: "Failed" },
    { File: "file6.xlsx", Reason: "Valid", Status: "Success" },
    { File: "file7.xlsx", Reason: "Valid", Status: "Success" },
    { File: "file8.xlsx", Reason: "Missing Columns", Status: "Failed" },
    { File: "file9.xlsx", Reason: "Valid", Status: "Success" },
    { File: "file10.xlsx", Reason: "Duplicate Data", Status: "Failed" },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <Header>
        <BackButton onClick={handleBack}>
          <MoveLeft size={24} />
        </BackButton>
        <Title>Bulk Upload</Title>
      </Header>

      <DescriptionContainer>
        {/* <DescriptionText>
          The download template is an Excel file with the required column
          headers. Please use this template for bulk uploads.
        </DescriptionText> */}
        <DownloadButton onClick={handleDownloadTemplate}>
          Download Template
        </DownloadButton>
      </DescriptionContainer>

      <UploadContainer onClick={handleUploadFiles}>
        <UploadText>Click or drag and drop to upload Excel files</UploadText>
      </UploadContainer>

      <TableView
        headings={headings}
        data={paginatedData}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        totalCount={data.length}
      />
    </Container>
  );
};

export default BulkUpload;
