import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Webview from "./components/webview/Webview.jsx";
import Mobileview from "./components/mobileview/Mobileview.jsx";
import AddCustomer from "./pages/admin/addCustomer/AddCustomer.jsx";
import AddProducts from "./pages/admin/addProduct/AddProducts.jsx";
import AddPurchaseRecord from "./pages/admin/addPurchaseRecord/AddPurchaseRecord.jsx";
import ViewCustomer from "./pages/admin/viewCustomer/ViewCustomer.jsx";
import ViewProducts from "./pages/admin/viewProduct/ViewProducts.jsx";
import ViewPurchaseRecord from "./pages/admin/viewPurchaseRecord/viewPurchaseRecord.jsx";
import styled from "styled-components";
import { AuthProvider, useAuth } from "./context/AuthContext";
import "aos/dist/aos.css";
import AOS from "aos";
import SignIn from "./pages/signin/SignIn.jsx";
import Cookies from "js-cookie";
import ProtectedRoute from "./routes/ProtectedRoute.js";
import CustomerPurchaseRecord from "./pages/customer/customerPurchaseRecord/CustomerPurchaseRecord.jsx";
import WebSignIn from "./pages/signin/WebSignIn.jsx";
import AddShopOwners from "./pages/superuser/addShopOwners/AddShopOwners.jsx";
import Dashboard from "./pages/superuser/dashboard/Dashboard.jsx";
import ShopOwners from "./pages/superuser/shopOwners/ShopOwners.jsx";
import Customers from "./pages/superuser/customers/Customers.jsx";
import Purchases from "./pages/superuser/purchases/Purchases.jsx";
import Products from "./pages/superuser/products/Products.jsx";
import Brands from "./pages/superuser/brands/Brands.jsx";
import Device from "./pages/superuser/device/Device.jsx";
import DevicePart from "./pages/superuser/devicePart/DevicePart.jsx";
import OwnerBrands from "./pages/admin/brands/OwnerBrands.jsx";
import OwnerDevice from "./pages/admin/device/OwnerDevice.jsx";
import OwnerDevicePart from "./pages/admin/devicePart/OwnerDevicePart.jsx";

AOS.init();

function App() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [collapseState, setCollapseState] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "/") {
        setCollapseState((prevCollapseState) => !prevCollapseState);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <AuthProvider>
      <Router>
        <ToastContainer containerId="1" />
        <Routes>
          <Route
            path="/signin"
            element={isMobile ? <SignIn /> : <WebSignIn />}
          />
          <Route
            path="*"
            element={
              <DefaultLayout
                isDesktopOrLaptop={isDesktopOrLaptop}
                isMobile={isMobile}
                collapseState={collapseState}
                setCollapseState={setCollapseState}
              />
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const DefaultLayout = ({
  isDesktopOrLaptop,
  isMobile,
  collapseState,
  setCollapseState,
}) => {
  const { user } = useAuth();
  const role = Cookies.get("role");
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className="">
      {isDesktopOrLaptop && (
        <Webview
          collapseState={collapseState}
          setCollapseState={setCollapseState}
        />
      )}
      {isMobile && <Mobileview />}
      <MainLayout collapseState={collapseState} isMobile={isMobile}>
        <ContentContainer>
          <Routes>
            <Route
              path="/admin/addCustomer"
              element={<ProtectedRoute component={AddCustomer} />}
            />
            <Route
              path="/admin/addProducts"
              element={<ProtectedRoute component={AddProducts} />}
            />
            {/* <Route
              path="/admin/addPurchaseRecord"
              element={<ProtectedRoute component={AddPurchaseRecord} />}
            /> */}
            <Route
              path="/admin/viewCustomer"
              element={<ProtectedRoute component={ViewCustomer} />}
            />
            <Route
              path="/admin/viewProducts"
              element={<ProtectedRoute component={ViewProducts} />}
            />
            <Route
              path="/admin/viewPurchaseRecord"
              element={<ProtectedRoute component={ViewPurchaseRecord} />}
            />
            <Route
              path="/admin/brands"
              element={<ProtectedRoute component={OwnerBrands} />}
            />
            <Route
              path="/admin/device"
              element={<ProtectedRoute component={OwnerDevice} />}
            />
            <Route
              path="/admin/deviceParts"
              element={<ProtectedRoute component={OwnerDevicePart} />}
            />
            {role === "Customer" && (
              <Route
                path="/customer/viewPurchaseRecord"
                element={<ProtectedRoute component={CustomerPurchaseRecord} />}
              />
            )}
            {role === "Admin" && (
              <>
                <Route
                  path="/superuser/addShopOwner"
                  element={<ProtectedRoute component={AddShopOwners} />}
                />
                <Route
                  path="/superuser/addProducts"
                  element={<ProtectedRoute component={AddProducts} />}
                />
                <Route
                  path="/superuser/dashboard"
                  element={<ProtectedRoute component={Dashboard} />}
                />
                <Route
                  path="/superuser/store"
                  element={<ProtectedRoute component={ShopOwners} />}
                />
                <Route
                  path="/superuser/customers"
                  element={<ProtectedRoute component={Customers} />}
                />
                <Route
                  path="/superuser/orders"
                  element={<ProtectedRoute component={Purchases} />}
                />
                {/* <Route
                  path="/superuser/products"
                  element={<ProtectedRoute component={Products} />}
                /> */}
                <Route
                  path="/superuser/brands"
                  element={<ProtectedRoute component={Brands} />}
                />
                <Route
                  path="/superuser/device"
                  element={<ProtectedRoute component={Device} />}
                />
                <Route
                  path="/superuser/deviceParts"
                  element={<ProtectedRoute component={DevicePart} />}
                />
              </>
            )}
            <Route path="*" element={<Navigate to="/signin" replace />} />
          </Routes>
        </ContentContainer>
      </MainLayout>
    </div>
  );
};

const MainLayout = styled.div`
  width: calc(
    100% -
      ${(props) =>
        props.isMobile ? "0" : props.collapseState ? "4rem" : "14rem"}
  ); /* Adjusted width based on collapse state */
  margin-left: ${(props) =>
    props.isMobile
      ? "0"
      : props.collapseState
      ? "4rem"
      : "14rem"}; /* Adjusted left margin based on collapse state */
  margin-top: 65px;
  transition: margin-left 0.3s ease;
`;

const ContentContainer = styled.div`
  padding: 20px;
  height: 100vh;
`;

export default App;
