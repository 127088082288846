import React, { useState } from "react";
import Input from "../../../components/input/Input";
import SelectField from "../../../components/selectfield/SelectField";
import Button from "../../../components/button/Button";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-end")};
`;

const AddPurchaseRecord = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [purchaseData, setPurchaseData] = useState({
    customerName: "",
    productName: "",
    quantity: "",
    pricePerItem: "",
    totalPrice: "",
  });

  const handleCancel = () => {
    // Clear input fields
    setPurchaseData({
      customerName: "",
      productName: "",
      quantity: "",
      pricePerItem: "",
      totalPrice: "",
    });
  };

  const handleAddRecord = () => {
    // Perform any necessary validations before adding record

    // Show alert message with record details
    const { customerName, productName, quantity, pricePerItem, totalPrice } =
      purchaseData;
    alert(
      `Purchase record added: Customer - ${customerName}, Product - ${productName}, Quantity - ${quantity}, Total Price - ${totalPrice}`
    );

    // Clear input fields after adding record
    setPurchaseData({
      customerName: "",
      productName: "",
      quantity: "",
      pricePerItem: "",
      totalPrice: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPurchaseData({
      ...purchaseData,
      [name]: value,
    });
  };

  const handleCustomerChange = (selectedOption) => {
    setPurchaseData({
      ...purchaseData,
      customerName: selectedOption.value, // Assuming you want to store the value of the selected option
    });
  };

  const handleProductChange = (selectedOption) => {
    setPurchaseData({
      ...purchaseData,
      productName: selectedOption.value, // Assuming you want to store the value of the selected option
    });
  };
  // Dummy options for SelectFields
  const customerOptions = [
    { value: "1", label: "Customer 1" },
    { value: "2", label: "Customer 2" },
    { value: "3", label: "Customer 3" },
  ];

  const productOptions = [
    { value: "1", label: "Product 1" },
    { value: "2", label: "Product 2" },
    { value: "3", label: "Product 3" },
  ];

  return (
    <div>
      <SelectField
        label="Customer Name"
        options={customerOptions}
        name="customerName"
        value={purchaseData.customerName}
        onChange={handleCustomerChange}
      />
      <SelectField
        label="Product Name"
        options={productOptions}
        name="productName"
        value={purchaseData.productName}
        onChange={handleProductChange}
      />
      <Input
        className="mb-3"
        placeholder="Enter Quantity"
        label="Quantity"
        name="quantity"
        type="Number"
        value={purchaseData.quantity}
        onChange={handleChange}
      />
      <Input
        className="mb-3"
        placeholder="Enter Price Per Item"
        label="Price Per Item"
        name="pricePerItem"
        type="Number"
        value={purchaseData.pricePerItem}
        onChange={handleChange}
      />
      <Input
        className="mb-3"
        placeholder="Enter Total Price"
        label="Total Price"
        type="Number"
        name="totalPrice"
        value={purchaseData.totalPrice}
        onChange={handleChange}
      />
      <ButtonContainer isMobile={isMobile}>
        <Button variant="secondary" onClick={handleCancel} fullWidth={isMobile}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleAddRecord}
          fullWidth={isMobile}
        >
          Submit
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default AddPurchaseRecord;
