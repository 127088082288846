import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid transparent;
  /* margin: 5px; */
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  /* Primary Button Styles */
  ${(props) =>
    props.variant === "primary" &&
    `
    background-color: var(--color-primary);
    color: white;
    border-color:var(--color-primary);

    &:hover {
      background-color: #e55b5b;
      border-color: #e55b5b;
    }
  `}

  /* Secondary Button Styles */
  ${(props) =>
    props.variant === "secondary" &&
    `
    background-color: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
    &:hover {
      background-color: #e55b5b;
      border-color: #e55b5b;
          color: white;
    }
  `}
`;

const Button = ({ variant, children, onClick, fullWidth }) => {
  return (
    <ButtonWrapper variant={variant} onClick={onClick} fullWidth={fullWidth}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
