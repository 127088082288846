import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import SelectField from "../../../components/selectfield/SelectField";
import TableView from "../../../components/tableview/TableView";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import { ScaleLoader } from "react-spinners";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import debounce from "lodash.debounce";
import Select from "react-select";
import { customStyles } from "../../../styles/selectStyles";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const AddDevicePartButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const SearchInput = styled(Input)`
  margin-bottom: 20px;
`;

const DevicePart = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [AllDevices, setAllDevices] = useState([]);
  const [deviceParts, setDeviceParts] = useState([]);

  const [tableLoading, setTableLoading] = useState(true);
  const [devicePartCount, setDevicePartCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 0-based index for current page
  const itemsPerPage = 10; // Number of items per page
  const [formData, setFormData] = useState({
    brand: "",
    device: "",
    partName: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  const headings = ["Part Name", "Device", "Brand"];

  const fetchBrands = async () => {
    try {
      const response = await axiosPrivate.get("/brands/get/all");
      const brandOptions = response.data.response.map((brand) => ({
        value: brand.id,
        label: brand.brand_name,
      }));
      setBrands(brandOptions);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const fetchDevices = async (brandId) => {
    try {
      const response = await axiosPrivate.get("/devices/get/all/", {
        params: { brand_id: brandId },
      });
      const deviceOptions = response.data.response.map((device) => ({
        value: device.id,
        label: device.device_name,
      }));
      setDevices(deviceOptions);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const fetchDeviceParts = async (page = 0) => {
    setTableLoading(true);
    try {
      const response = await axiosPrivate.get("/device-parts/get/all/", {
        params: {
          page: page + 1, // Convert 0-based to 1-based for API
          page_size: itemsPerPage,
        },
      });

      const processedDeviceParts = response.data.response.map((part) => ({
        "Part Name": part.part_name,
        Device: part.device_name,
        Brand: part.brand_name,
      }));

      setDeviceParts(processedDeviceParts);
      setDevicePartCount(response.data.count);
    } catch (error) {
      console.error("Error fetching device parts:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const fetchAllDevices = async (brandId) => {
    try {
      const response = await axiosPrivate.get("/devices/get/all/", {});
      const deviceOptions = response.data.response.map((device) => ({
        value: device.id,
        label: device.device_name,
      }));
      setAllDevices(deviceOptions);
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const searchDeviceParts = async (term, page = 0) => {
    if (term) {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.get("/device-parts/get/all/", {
          params: {
            part_name: term,
            page: page + 1,
            limit: itemsPerPage,
          },
        });
        const processedData = response.data.response.map((part) => ({
          "Part Name": part.part_name,
          Device: part.device_name,
          Brand: part.brand_name,
        }));

        setDeviceParts(processedData);
        setDevicePartCount(response.data.count);
      } catch (error) {
        console.error("Error searching device parts:", error);
      } finally {
        setTableLoading(false);
      }
    } else {
      fetchDeviceParts(page);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term, page) => searchDeviceParts(term, page), 500),
    []
  );

  useEffect(() => {
    fetchBrands();
    fetchDeviceParts();
    fetchAllDevices();
  }, []);

  useEffect(() => {
    debouncedSearch(searchTerm, currentPage);
  }, [searchTerm, debouncedSearch, currentPage]);

  const toggleModal = () => {
    console.log("open modal");
    setIsModalOpen(!isModalOpen);
    setFormData({ brand: "", device: "", partName: "" });
    setDevices([]);
    setError("");
  };

  const handleBrandChange = (option) => {
    setFormData({ ...formData, brand: option.value });
    fetchDevices(option.value); // Fetch devices when a brand is selected
    setError(""); // Clear the error when brand changes
  };

  const handleDeviceChange = (option) => {
    setFormData({ ...formData, device: option.value });
    setError(""); // Clear the error when device changes
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear the error when input changes
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async () => {
    const { brand, device, partName } = formData;

    if (!brand || !device || !partName.trim()) {
      setError("All fields are required");
      return;
    }

    setLoading(true);
    try {
      await axiosPrivate.post("/device-part/add/", {
        device_id: device,
        part_name: partName,
      });

      toast.success(`Device Part added: ${partName}`, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });

      toggleModal();
      fetchDeviceParts(currentPage); // Fetch updated device parts list after adding new device part
    } catch (err) {
      toast.error("An error occurred while adding the device part", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (selectedItem) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage); // React Paginate is 0-based
    if (searchTerm) {
      searchDeviceParts(searchTerm, selectedPage);
    } else {
      fetchDeviceParts(selectedPage);
    }
  };

  const [filterbrands, setFilterBrands] = useState([]);
  const [filterQueryString, setFilterQueryString] = useState([]);
  // Handle the selection of brands from the dropdown
  const handleFilterByBrands = async (selected) => {
    setFilterBrands(selected);
    setTableLoading(true); // Start loading

    try {
      const queryString = selected
        .map((brand) => `brand_id=${brand.value}`)
        .join("&");
      setFilterQueryString(queryString);
      const response = await axiosPrivate.get(
        `/device-parts/get/all/?${queryString}`
      );
      const processedData = response.data.response.map((part) => ({
        "Part Name": part.part_name,
        Device: part.device_name,
        Brand: part.brand_name,
      }));

      setDeviceParts(processedData);
      setDevicePartCount(response.data.count);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setTableLoading(false); // Stop loading
    }
  };

  const handleFilterByDevices = async (selected) => {
    setTableLoading(true); // Start loading

    try {
      const deviceString = selected
        .map((brand) => `device_id=${brand.value}`)
        .join("&");
      const response = await axiosPrivate.get(
        `/device-parts/get/all/?${deviceString}&${filterQueryString}`
      );
      const processedData = response.data.response.map((part) => ({
        "Part Name": part.part_name,
        Device: part.device_name,
        Brand: part.brand_name,
      }));

      setDeviceParts(processedData);
      setDevicePartCount(response.data.count);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setTableLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      <Header>
        <Title>Device Parts</Title>
        <AddDevicePartButton onClick={toggleModal}>
          Add Device Part
        </AddDevicePartButton>
      </Header>

      <div className="row">
        <div className="col-md-4 mb-3">
          <Select
            placeholder="Filter by Brands"
            isMulti
            options={brands}
            onChange={handleFilterByBrands}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: " #ff6969", // Color of the selected option
              },
            })}
            styles={customStyles}
          />
        </div>
        <div className="col-md-4 mb-3">
          <Select
            placeholder="Filter by Devices"
            isMulti
            options={AllDevices}
            onChange={handleFilterByDevices}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: " #ff6969", // Color of the selected option
              },
            })}
            styles={customStyles}
          />
        </div>
        <div className="col-md-4">
          <SearchInput
            placeholder="Search by device part name"
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
        </div>
      </div>

      <div>
        {tableLoading ? (
          <SkeletonTableView />
        ) : deviceParts.length === 0 && devicePartCount === 0 ? (
          <Error>No device parts found</Error>
        ) : (
          <TableView
            headings={headings}
            data={deviceParts}
            pageCount={Math.ceil(devicePartCount / itemsPerPage)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalCount={devicePartCount}
          />
        )}
      </div>

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Add Device Part</ModalHeader>
        <ModalBody>
          <FormField>
            <SelectField
              label="Select Brand"
              options={brands}
              value={formData.brand}
              onChange={handleBrandChange}
            />
          </FormField>
          <FormField>
            <SelectField
              label="Select Device"
              options={devices}
              value={formData.device}
              onChange={handleDeviceChange}
              isDisabled={!formData.brand}
            />
          </FormField>
          <FormField>
            <Input
              label="Part Name"
              name="partName"
              value={formData.partName}
              onChange={handleInputChange}
              maxLength={30}
            />
          </FormField>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="primary" disabled={loading}>
            {loading ? <ScaleLoader color="white" height={15} /> : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer />
    </Container>
  );
};

export default DevicePart;
