import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import styled, { keyframes } from "styled-components";

const Accordion = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #f7f7f7;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e2e2e2;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100vh;
  }
`;

const AccordionContent = styled.div`
  padding: 10px;
  background-color: #fff;
  animation: ${fadeIn} 0.3s ease forwards;
  position: relative;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

const ItemDetail = styled.span`
  flex: 1;
  text-align: left;
  padding: 0.5rem;
`;

const Total = styled.div`
  display: flex;
  justify-content: end;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-top: 1px solid #ff6969;
`;

const SuccessTag = styled.div`
  color: #157e38;
  background-color: #eefdf3;
  padding: 3px 9px;
  border-radius: 14px;
  border: 1px solid #157e38;
  font-size: small;
`;

const PendingTag = styled.div`
  color: #98690c;
  background-color: #fef9ee;
  padding: 3px 9px;
  border-radius: 14px;
  border: 1px solid #98690c;
  font-size: small;
`;

const PurchaseAccordion = ({
  title,
  items,
  total,
  status,
  customer_name = "empty",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  console.log("sfdf", items)
  return (
    <Accordion>
      <AccordionHeader onClick={toggleAccordion}>
        <div className="d-flex gap-3">
          <div className="fw-bold">{title}</div>
          {customer_name !== "empty" && (
            <div className="fw-light">{customer_name}</div>
          )}
        </div>

        <div className="d-flex align-items-center gap-3">
          {status === "Paid" && <SuccessTag>Paid</SuccessTag>}
          {status === "Pending" && <PendingTag>Pending</PendingTag>}

          <div>{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
        </div>
      </AccordionHeader>
      {isOpen && (
        <AccordionContent>
          <ItemList>
            {items.map((item, index) => (
              <Item key={index}>
                <ItemDetail>{item.brand_name}</ItemDetail>
                <ItemDetail>{item.device_name}</ItemDetail>
                <ItemDetail>{item.device_part_name}</ItemDetail>
                <ItemDetail>{item.quantity}</ItemDetail>
                <ItemDetail>${item.price_per_item.toFixed(2)}</ItemDetail>
              </Item>
            ))}
          </ItemList>
          <Total>Total: ${total}</Total>
        </AccordionContent>
      )}
    </Accordion>
  );
};

export default PurchaseAccordion;
