import React, { useState } from "react";
import styled from "styled-components";

// Primary color
const primaryColor = "#ff6969";

const ToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ToggleLabel = styled.label`
  font-size: 1rem;
  margin-right: 1rem;
  font-weight: bold;
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Slider = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
`;

const ToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  ${ToggleInput}:checked + ${Slider} {
    background-color: ${primaryColor};
  }

  ${ToggleInput}:checked + ${Slider}:before {
    transform: translateX(20px);
  }
`;

const ToggleButton = ({ label, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked); // Pass the new state to the parent component
  };

  return (
    <ToggleSwitch>
      <ToggleLabel>{label}</ToggleLabel>
      <ToggleWrapper>
        <ToggleInput
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
        <Slider />
      </ToggleWrapper>
    </ToggleSwitch>
  );
};

export default ToggleButton;
