import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import TableView from "../../../components/tableview/TableView";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import Input from "../../../components/input/Input";
import debounce from "lodash.debounce";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const SearchInput = styled(Input)`
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const Customers = () => {
  const axiosPrivate = useAxiosPrivate();
  const headings = ["Name", "Email", "Mobile"];
  const [customers, setCustomers] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [customerCount, setCustomerCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");

  const processApiResponse = (apiResponse) => {
    return apiResponse.response.map((item) => ({
      Name: item.name,
      Email: item.email,
      Mobile: item.mobile,
    }));
  };

  const fetchCustomers = async (page = 0) => {
    setTableLoading(true);
    try {
      const response = await axiosPrivate.get("/get-all-customers/", {
        params: {
          page: page + 1,
          page_size: itemsPerPage,
        },
      });
      const processedData = processApiResponse(response.data);

      setCustomers(processedData);
      setCustomerCount(response.data.count);
    } catch (err) {
      console.error("Error fetching customers:", err);
    } finally {
      setTableLoading(false);
    }
  };

  const searchCustomers = async (term, page = 0) => {
    if (term) {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.get("/get-all-customers/", {
          params: {
            full_name: term,
            page: page + 1,
            page_size: itemsPerPage,
          },
        });

        const processedData = processApiResponse(response.data);

        setCustomers(processedData);
        setCustomerCount(response.data.count);
      } catch (err) {
        console.error("Error searching customers:", err);
      } finally {
        setTableLoading(false);
      }
    } else {
      fetchCustomers(page);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term, page) => searchCustomers(term, page), 500),
    []
  );

  useEffect(() => {
    fetchCustomers(currentPage);
  }, [currentPage]);

  useEffect(() => {
    debouncedSearch(searchTerm, currentPage);
  }, [searchTerm, debouncedSearch, currentPage]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (selectedItem) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage);
    if (searchTerm) {
      searchCustomers(searchTerm, selectedPage);
    } else {
      fetchCustomers(selectedPage);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Customers</Title>
      </Header>

      <SearchInput
        placeholder="Search by customer name"
        value={searchTerm}
        onChange={handleSearchInputChange}
      />

      {tableLoading ? (
        <SkeletonTableView />
      ) : customers.length === 0 && customerCount === 0 ? (
        <Error>No customers found</Error>
      ) : (
        <TableView
          headings={headings}
          data={customers}
          pageCount={Math.ceil(customerCount / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          totalCount={customerCount}
        />
      )}
    </Container>
  );
};

export default Customers;
