import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import TableView from "../../../components/tableview/TableView";
import SkeletonTableView from "../../../components/tableview/SkeletonTableView";
import { ScaleLoader } from "react-spinners";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import debounce from "lodash.debounce"; // Import lodash debounce function

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
`;

const AddBrandButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Error = styled.div`
  color: red;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const SearchInput = styled(Input)`
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

const Brands = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [brandCount, setBrandCount] = useState(0); // Track total brands
  const [currentPage, setCurrentPage] = useState(0); // Track current page
  const itemsPerPage = 10; // Number of items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(""); // State for error message

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setBrandName("");
    setError(""); // Clear the error message when modal is toggled
  };

  const handleInputChange = (e) => {
    setBrandName(e.target.value);
    setError(""); // Clear the error when input changes
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    debouncedSearch(value, currentPage);
  };

  const handleSubmit = async () => {
    if (brandName.trim() === "") {
      setError("Brand name cannot be empty"); // Set the error message if input is empty
      return;
    }

    setLoading(true);
    try {
      await axiosPrivate.post("/brand/add/", {
        brand_name: brandName,
      });

      toast.success(`Brand added: ${brandName}`, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });

      toggleModal();
      fetchBrands(currentPage); // Fetch updated brand list after adding new brand
    } catch (err) {
      const errMsg = err.response.data.error;
      setError(errMsg);
      console.log("error", err);
      toast.error("An error occurred while adding the brand", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchBrands = async (page = 0) => {
    setTableLoading(true);
    try {
      const response = await axiosPrivate.get("/brands/get/all/", {
        params: {
          page: page + 1, // Convert 0-based to 1-based for API
          page_size: itemsPerPage,
        },
      });
      const processedData = response.data.response.map((brand) => ({
        Name: brand.brand_name,
      }));

      setBrands(processedData);
      setBrandCount(response.data.count); // Total count of brands
    } catch (err) {
      console.error("Error fetching brands:", err);
    } finally {
      setTableLoading(false);
    }
  };

  const searchBrands = async (term, page = 0) => {
    console.log("term", term);
    if (term) {
      setTableLoading(true);
      try {
        const response = await axiosPrivate.get(`/brands/get/all/`, {
          params: {
            brand_name: term,
            page: page + 1, // Convert 0-based to 1-based for API
          },
        });

        const processedData = response.data.response.map((brand) => ({
          Name: brand.brand_name,
        }));

        setBrands(processedData);
        setBrandCount(response.data.count); // Total count of brands
      } catch (err) {
        console.error("Error searching brands:", err);
      } finally {
        setTableLoading(false);
      }
    } else {
      fetchBrands(page);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term, page) => {
      searchBrands(term, page);
    }, 500),
    []
  );

  useEffect(() => {
    if (!searchTerm) {
      fetchBrands(currentPage);
    }
  }, [currentPage]);

  const handlePageChange = (selectedItem) => {
    const selectedPage = selectedItem.selected;
    setCurrentPage(selectedPage); // Update the current page state
    if (searchTerm) {
      searchBrands(searchTerm, selectedPage);
    } else {
      fetchBrands(selectedPage);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Brands</Title>
        <AddBrandButton onClick={toggleModal}>Add Brand</AddBrandButton>
      </Header>

      <SearchInput
        placeholder="Search by brand name"
        value={searchTerm}
        onChange={handleSearchInputChange}
      />

      {tableLoading ? (
        <SkeletonTableView />
      ) : brands.length === 0 && brandCount === 0 ? (
        <Error>No brands found</Error>
      ) : (
        <TableView
          headings={["Name"]}
          data={brands}
          pageCount={Math.ceil(brandCount / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          totalCount={brandCount}
        />
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Add Brand</ModalHeader>
        <ModalBody>
          <FormField>
            <Input
              label="Brand Name"
              placeholder="Enter brand name"
              value={brandName}
              onChange={handleInputChange}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}{" "}
            {/* Error message */}
          </FormField>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? <ScaleLoader color="white" height={10} /> : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </Container>
  );
};

export default Brands;
